import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useReportApi() {

    const callApi = useApiCall();

    const balanceSheetData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.BALANCE_SHEET, data, onSuccess, onError);
    }

    const balanceSheetRefresh = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.REFRESH_BALANCE_SHEET, data, onSuccess, onError);
    }

    const profitLossData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.PROFIT_LOSSS, data, onSuccess, onError);
    }

    const cashFlowData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.STATEMENT_OF_CASH_FLOW, data, onSuccess, onError);
    }

    const dashboardRaioData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.DASHBOARD_RATIO, data, onSuccess, onError);
    }

    const downloadReport = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.REPORT_DOWNLOAD_BS, data, onSuccess, onError);
    }
    
    const downloadBTReport = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.REPORT_DOWNLOAD_BT, data, onSuccess, onError);
    }

    const txnRegisterPL = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.REPORT.TXN_REGISTER_PL, data, onSuccess, onError);
    }

    return {
        balanceSheetData,
        balanceSheetRefresh,
        profitLossData,
        cashFlowData,
        dashboardRaioData,
        downloadReport,
        downloadBTReport,
        txnRegisterPL
    }
}