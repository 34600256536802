
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import SAlert from '../../components/SAlert';
import { getUser } from '../../store/user/selectors'
//import { AddBudgetIFormI, BalanceFormI } from '../../resources/form-props';
import { getAccountSettings, getBudgetSettings } from '../../store/settings/selectors'
import { useBudgetApi } from '../../resources/hooks/api/useBudgetApiHook';
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import filterSolidIcon from '../../assets/images/filter-solid-icon.svg';
import publishBudgetIcon from '../../assets/images/billing-icon-w.svg';
import { MONTHS } from '../../resources/constants';
import { nextYears, nextInclomeTaxYears, calculateMaxHeightContent, capitalizeFirstCharacter, usdCurrencyFormat } from '../../resources/functions';
import { currencyFormat } from '../../resources/helper';
import { BudgetValidate } from '../../resources/form-validator';

import { getBranch } from '../../store/user/selectors';
import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';

interface Props {

}

interface BudgetMonthI {
    amount: number
}
interface BudgetField {
    master_chart_of_account_number: string,
    master_chart_of_account_name: string,
    master_chart_of_account_id: number,
    parent_id: number,
    [key: `month_${number}`]: BudgetMonthI;
    // [key: string]: BudgetMonthI // To handle dynamic month properties
}

interface AddBudgetIFormI {
    budgets: BudgetField[];
}

const Budget: React.FC<Props> = () => {

    const budgetSettings = useSelector(getBudgetSettings)
    const { fetchBudget, postBudget, updateBudget, publishBudget } = useBudgetApi();
    const { fetchAccountSettingsData } = useSettingsApi();

    const [glBudgetList, setGlBudgetList] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useState<any>({
        year: null
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRecordsExists, setIsRecordsExists] = useState<boolean>(false);
    const [isBudgetPublished, setIsBudgetPublished] = useState<boolean>(false);
    const [searchError, setSearchError] = useState<string>('');
    const [tableHeaders, setTableHeaders] = useState<any[]>([]);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);
    const [showBudgetAlert, setShowBudgetAlert] = useState<any>({
        timestamp: new Date().getTime(),
        show: false
    });
    // Initialize budgetValues as an array of arrays
    const [budgetValues, setBudgetValues] = useState<number[][]>([]);
    const [totalAmount, setTotalAmount] = useState<number[]>([]);

    const branchData = useSelector(getBranch)

    // const { register, reset, trigger, control, handleSubmit, formState: { errors } } = useForm<AddBudgetIFormI>({
    //     defaultValues: {
    //         budgets: []
    //     },
    //     resolver: yupResolver(BudgetValidate)
    // });
    const { register, reset, trigger, control, handleSubmit, setValue, formState: { errors } } = useForm<AddBudgetIFormI>({
        defaultValues: {
            budgets: []
        },
        resolver: yupResolver(BudgetValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "budgets"
    });


    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(78));
    })
    useEffect(() => {
        getSettingsData()
    }, [])
    useEffect(() => {
        if (glBudgetList.length) {
            setTableHeaders(glBudgetList[0]['details'])
        }

        reset({ 'budgets': [] });
        glBudgetList.map((record: any, index: number) => {
            var accTotalAmount = 0;
            var monthAmounts = {
                month_0: {
                    amount: 0
                },
                month_1: {
                    amount: 0
                },
                month_2: {
                    amount: 0
                },
                month_3: {
                    amount: 0
                },
                month_4: {
                    amount: 0
                },
                month_5: {
                    amount: 0
                },
                month_6: {
                    amount: 0
                },
                month_7: {
                    amount: 0
                },
                month_8: {
                    amount: 0
                },
                month_9: {
                    amount: 0
                },
                month_10: {
                    amount: 0
                },
                month_11: {
                    amount: 0
                },
            }
            record['details'].map((eachMonth: any, monthIndex: number) => {
                monthAmounts = {
                    ...monthAmounts,
                    [`month_${monthIndex}`]: {
                        amount: +eachMonth['amount'] || 0
                    }
                }
                accTotalAmount += +eachMonth['amount'] || 0;
            })
            formAppend({
                master_chart_of_account_number: record['account_chart']['account'],
                master_chart_of_account_name: record['account_chart']['name'],
                master_chart_of_account_id: record['account_chart']['id'],
                parent_id: record['account_chart']['parent_id'],
                ...monthAmounts
            })
            totalAmount[index] = accTotalAmount;
            setTotalAmount(totalAmount);
        })
        // setFocus(`budgets.0.month_0`)
    }, [glBudgetList])

    const getSettingsData = () => {
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {

        }, (msg: string, resp: any) => {

        })
    }

    const fetchBudgetDetails = () => {

        if (searchParams && searchParams['year']) {
            const params = {
                year: +searchParams['year']['value'],
                branch_id: +branchData['id']
            }
            setIsLoading(true);
            fetchBudget(params, (message: string, resp: any) => {
                setIsLoading(false);
                setTotalAmount(new Array(resp.data.data.data.length).fill(0));
                setIsRecordsExists(resp.data.data.budget_exists);
                setIsBudgetPublished(resp.data.data.budget_published);
                setGlBudgetList(resp.data.data.data);
                // if (alterBudgetAlert) {
                //     setShowBudgetAlert({
                //         timestamp: new Date().getTime(),
                //         show: resp.data.data.budget_exists
                //     })
                // }
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message)
            })
        } else {
            setSearchError('Please select year')
        }
    }

    const _handleChangeSearchParam = (e: any) => {
        setSearchError('')
        setSearchParams({
            year: e
        })
    }

    const processData = (data: any) => {
        const formData = data['budgets'].reduce((recordAcc: any[], record: any) => {
            const result = tableHeaders.reduce((acc: any[], c: any, currentIndex: number) => {
                acc.push({
                    "master_chart_of_account_id": record['master_chart_of_account_id'],
                    parent_id: record['parent_id'],
                    "total_amount": (record[`month_${currentIndex}`]['amount'] || '0').replace(/,/g, ''),
                    "month": c['month_count'],
                    "year": c['year']
                });
                return acc;
            }, [])
            recordAcc.push.apply(recordAcc, result);
            return recordAcc;
        }, [])
        // console.log('data: ', formData)
        const params = {
            year: +searchParams['year']['value'],
            branch_id: +branchData['id'],
            formData
        }
        setIsLoading(true);
        if (isRecordsExists) {
            updateBudget(params, (message: string, resp: any) => {
                setIsLoading(false);
                fetchBudgetDetails();
                toast.success(message)
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message)
            })
        } else {
            postBudget(params, (message: string, resp: any) => {
                setIsLoading(false);
                fetchBudgetDetails();
                toast.success(message)
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message)
            })
        }
    }

    const _handlePublishBudget = () => {
        closeBudgetModal();

        const params = {
            year: +searchParams['year']['value'],
            branch_id: +branchData['id']
        }
        setIsLoading(true);
        publishBudget(params, (message: string, resp: any) => {
            setIsLoading(false);
            fetchBudgetDetails();
            toast.success(message)
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message)
        })
    }

    const closeBudgetModal = () => {
        setShowBudgetAlert({
            timestamp: new Date().getTime(),
            show: false
        })
    }

    const publshBudgetConfirmation = () => {
        setShowBudgetAlert({
            timestamp: new Date().getTime(),
            show: true
        })
    }

    // Function to handle changes in budget values
    const handleBudgetChange = (rowIndex: number, colIndex: number, value: number) => {
        // Make a copy of budgetValues to avoid mutating state directly
        const updatedBudgetValues = [...budgetValues];
        console.log('updatedBudgetValues ', updatedBudgetValues)

        // Check if the row exists, if not, initialize it as an empty array
        if (!updatedBudgetValues[rowIndex]) {
            updatedBudgetValues[rowIndex] = [];
        }

        // Update the value in the array
        // updatedBudgetValues[rowIndex][colIndex] = currencyFormat(parseFloat(value || '0').toFixed(2))
        updatedBudgetValues[rowIndex][colIndex] = value;

        // Update the state with the new array
        setBudgetValues(updatedBudgetValues);
    };


    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, rowIndex: number, colIndex: number) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput: HTMLInputElement | null = document.querySelector(`#budgets-${rowIndex}-${colIndex + 1}`) ||
                document.querySelector(`#budgets-${rowIndex + 1}-${0}`);
            nextInput?.focus();
        }
    }
    const calculateRowTotal = (row: BudgetField, index: number) => {
        const sum = Object.keys(row).reduce((total: any, key: any) => {
            if (key.startsWith('month_')) {
                const amount = +parseFloat('' + row[key]?.amount).toFixed(2);
                return total + amount;

                // if (typeof amount === 'string') {
                //     return total + parseFloat(amount.replace(/,/g, '') || '0');
                // } else if (typeof amount === 'number') {
                //     return total + amount;
                // }
            }
            return total;
        }, 0)//.toFixed(2);
        // setValue(`budgets.${index}.total_amount`, sum);
        totalAmount[index] = sum;
        setTotalAmount(JSON.parse(JSON.stringify(totalAmount)));
        // return sum;
    };

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Budget</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="filter-wrap d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-grow-1">
                                    <div className="filter-box w-25">
                                        <Select
                                            placeholder={budgetSettings?.budget_based_on?.value == 1 ? 'Select Calendar Year' : 'Select Fiscal Year'}
                                            onChange={(e: any) => _handleChangeSearchParam(e)}
                                            options={budgetSettings?.budget_based_on?.value == 1 ? nextYears(5) : nextInclomeTaxYears(10)}
                                            className={'zindex-10'}
                                        />
                                        {
                                            searchError && <span className='text-danger'>{searchError}</span> || <></>
                                        }
                                    </div>
                                    <div className="filter-btn">
                                        <button type="button" onClick={() => fetchBudgetDetails()}><img src={filterSolidIcon} alt="" /> Generate</button>
                                    </div>
                                    {fields.length > 0 && isRecordsExists && !isBudgetPublished && (
                                        <div className="filter-btn ms-auto">
                                            <button type="button" onClick={publshBudgetConfirmation} className="button-tertiary">
                                                <img src={publishBudgetIcon} alt="" /> Publish Budget
                                            </button>
                                        </div>
                                    )}
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard">
                    <Form>
                        <div className="table-responsive list-table table-scroll" >
                            {
                                fields.length > 0 && (
                                    <table className="table table-default">
                                        <thead>
                                            <tr className="align-middle">
                                                <th className="text-center">Account Name</th>
                                                {
                                                    tableHeaders.map((calendar: any) => <th className="text-center">{capitalizeFirstCharacter(calendar?.month)}{' '}{calendar?.year}</th>)
                                                }
                                                <th className="text-center">Total</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {fields.map((data, rowIndex: number) => (
                                                <tr className='align-middle h-60' key={rowIndex}>
                                                    <td>
                                                        <span>{fields[rowIndex]['master_chart_of_account_number']}{' '}{fields[rowIndex]['master_chart_of_account_name']}</span>
                                                    </td>
                                                    {tableHeaders.map((calendar, colIndex: number) => {
                                                        return (
                                                            <>
                                                                <td key={colIndex} className='login-input-container text-center'>
                                                                    {isBudgetPublished
                                                                        ? <span>{currencyFormat(fields[rowIndex][`month_${colIndex}`]['amount'] || 0)}</span>
                                                                        : <div>
                                                                            <Controller
                                                                                name={`budgets.${rowIndex}.month_${colIndex}.amount`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <InputGroup hasValidation>
                                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                                        <CurrencyInput
                                                                                            className={`form-control`}
                                                                                            placeholder="0.00"
                                                                                            value={fields[rowIndex][`month_${colIndex}`]['amount']}
                                                                                            onValueChange={(value) => {
                                                                                                field.onChange(value || 0);
                                                                                                calculateRowTotal(data, rowIndex);
                                                                                            }
                                                                                            }
                                                                                            allowDecimals={true}
                                                                                            decimalsLimit={2}
                                                                                            decimalSeparator="."
                                                                                            groupSeparator=","
                                                                                            disableAbbreviations
                                                                                        />
                                                                                        {/* <CurrencyInput
                                                                                            className='form-control login-input'
                                                                                            id={`budgets-${rowIndex}-${colIndex}`}
                                                                                            {...register(`budgets.${rowIndex}.month_${colIndex}.amount` as const)}
                                                                                            title={budgetValues[rowIndex]?.[colIndex] ?? '$ 0.00'}
                                                                                            placeholder="0.00"
                                                                                            decimalsLimit={2}
                                                                                            //value={field.value}
                                                                                            onValueChange={(value) => {
                                                                                                const parsedValue = (value || '').replace(/,/g, ''); // Remove commas
                                                                                                field.onChange(parsedValue || '0');
                                                                                                handleBudgetChange(rowIndex, colIndex, value || '0');
                                                                                            }}

                                                                                            //onChange={(e) => handleBudgetChange(rowIndex, colIndex, e.target.value)}
                                                                                            onKeyDown={(e) => {
                                                                                                handleKeyDown(e, rowIndex, colIndex);

                                                                                                const allowedKeys = [
                                                                                                    'Backspace',
                                                                                                    'Tab',
                                                                                                    'ArrowLeft',
                                                                                                    'ArrowRight',
                                                                                                    'ArrowUp',
                                                                                                    'ArrowDown',
                                                                                                    'Delete',
                                                                                                    'Enter',
                                                                                                    'Escape',
                                                                                                ];

                                                                                                // Allow control keys
                                                                                                if (allowedKeys.includes(e.key)) {
                                                                                                    return;
                                                                                                }

                                                                                                // Allow only numbers, comma, and dot
                                                                                                if (!/^[0-9.]$/.test(e.key)) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}

                                                                                        /> */}

                                                                                    </InputGroup>
                                                                                )}
                                                                            />

                                                                            {errors?.budgets?.[rowIndex]?.[`month_${colIndex}`]?.amount && (
                                                                                <Form.Text className="text-danger d-flex">
                                                                                    <strong>
                                                                                        {errors.budgets[rowIndex]?.[`month_${colIndex}`]?.amount?.message as ReactNode || ''}
                                                                                    </strong>
                                                                                </Form.Text>
                                                                            )}

                                                                        </div>
                                                                    }
                                                                </td>

                                                            </>
                                                        );
                                                    })}
                                                    <td className="text-center">
                                                        {/* {usdCurrencyFormat(calculateRowTotal(data))} */}
                                                        {usdCurrencyFormat(totalAmount[rowIndex] || 0)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )
                            }

                        </div>

                        {
                            fields.length > 0 && !isBudgetPublished && (
                                <div className="col-md-12 mt-3 text-center">
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit(processData)}>
                                        {isRecordsExists ? 'Update' : 'Save'} Budget
                                    </button>
                                </div> || <></>
                            )
                        }
                    </Form>
                </div>
            </div>

            <SAlert
                msg={'Are you sure to publish the budget for the selected year? Once it\'s published you can\'t update the budget any more.'}
                show={showBudgetAlert['show']}
                title={'Are you sure?'}
                onConfirm={_handlePublishBudget}
                confirmBtnText='Yes, Publish'
                cancelBtnText='Cancel'
                showCancel={true}
                onCancel={() => closeBudgetModal()}
            />
        </div>

    )
}

export default Budget;