import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { getMappedAcc } from '../../store/user/selectors'
import { getAccountSettings } from '../../store/settings/selectors'

export function basicStepsHook() {

  const accDetails = useSelector(getAccountSettings);

  const basicStepChecker = () => {
    return {
      is_beginning_balance_exists: accDetails.is_beginning_balance_exists || 0,
      is_coa_modified: accDetails.is_coa_modified || 0
    };
  }
  const [basicSteps, setBasicSteps] = useState(basicStepChecker())

  useEffect(() => {
    setBasicSteps(basicStepChecker())
  }, [accDetails.is_beginning_balance_exists, accDetails.is_coa_modified]);

  return basicSteps
}