import { Outlet, Navigate } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { basicStepsHook } from '../../resources/hooks/basicStepsHook'

const COAExixts = () => {
    const basisStep = basicStepsHook();

    return(
        basisStep.is_coa_modified == 1 ? <Outlet/> : <Navigate to={URLS.DASHBOARD} />
    )
}

export default COAExixts