import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import moment from 'moment';
import { GL_VP, SELECT_CUSTOM_STYLE } from '../../../resources/constants';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { getBranch } from '../../../store/user/selectors'
import { useReportApi } from '../../../resources/hooks/api/useReportApiHook';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { useBankFeedApi } from '../../../resources/hooks/api/bankFeedApiHook'
import { transTableDataApi } from '../../../resources/hooks/api/transApiHook';
import { usdCurrencyFormat } from 'resources/functions';
import { Spinner } from 'react-bootstrap';

interface ViewRegisterModalProps {
    show: boolean;
    onHide: () => void;
    startDate: string;
    endDate: string;
    account: number;
    hasEditAccess: boolean;
}


const ViewRegisterModal: React.FC<ViewRegisterModalProps> = ({
    show, onHide, startDate, endDate, account, hasEditAccess
}) => {
    const { txnRegisterPL } = useReportApi();
    const { glAccountsList } = useChartAccountApi();
    const { feedUpdate } = useBankFeedApi();

    const branchData = useSelector(getBranch);


    const [data, setData] = useState<any>([]);
    const [calculatedTotalAmount, setCalculatedTotalAmount] = useState<any>({
        totalCredit: 0,
        totalDebit: 0
    });
    const [glCreditAccount, setGlCreditAccount] = useState<any[]>([]);
    const [glAllAccount, setGlAllAccount] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#fff'));

    useEffect(() => {
        fechAllAccountList().then(r => {
            setGlCreditAccount(r);
        })


        fechAllAccountList('', [...GL_VP.SALES_ACTIVITY.account, ...GL_VP.BANK_ACCOUNT.account]).then(r => {
            setGlAllAccount(r);
        })

    }, [])

    useEffect(() => {
        fetchBankFeedList();
    }, [startDate, endDate, account])

    const fechAllAccountList = (searchText: string = '', excludeVP: number[] = []): Promise<any[]> => {
        return new Promise<any>((resolve1, reject1) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }

            if (excludeVP.length) {
                params['parent_account_not_in'] = excludeVP.join(',')
            }
            // params['parent_name_in'] = 'BANK ACCOUNTS';
            params['parent_account_not_in'] = GL_VP.BANK_ACCOUNT.account.join(',');

            glAccountsList(params, (message: string, resp: any) => {

                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                // setGlAllAccount(data)
                return resolve1(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve1([]);
            })
        })
    }


    const fetchBankFeedList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            start_date: startDate,
            end_date: endDate,
            account: account,
            branch_id: +branchData['id'],
            is_updated: 1
        }

        // if (selectedCalendar['amount'] !== null) {
        //     params['amount'] = selectedCalendar['amount'];
        // }

        setIsLoading(true);

        txnRegisterPL(params, (message: string, resp: any) => {

            const storeData: any[] = [];
            const result = resp.data.data.result;
            result.map((rec: any, index: any) => {

                const header = rec.breakdowns.map((breakdown: any, index: any) => {

                    if (breakdown.account_chart) {
                        return {
                            id: breakdown.id,
                            previous_master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                label: breakdown.account_chart.name + '(' + breakdown.account_chart.account + ')',
                                value: breakdown.account_chart.id
                            } || null,
                            master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                label: breakdown.account_chart.name + '(' + breakdown.account_chart.account + ')',
                                value: breakdown.account_chart.id
                            } || null,
                            account_type: breakdown.account_type,
                            name: breakdown.account_chart && breakdown.account_chart.name || '',
                            account: breakdown.account_chart && breakdown.account_chart.account || '',
                            account_value: rec.account_type == 1 && breakdown.account_type == 'Credit_Deposit' && breakdown.txn_type == 2 ? breakdown.account_value * -1 : breakdown.account_value,
                            debit: breakdown.txn_type == 1 ? breakdown.account_value : 0,
                            credit: breakdown.txn_type == 2 ? breakdown.account_value : 0,
                            revrex_account_id: breakdown.account_chart && breakdown.account_chart.revrex_account_id || null,
                            isEdit: false,
                            showLoader: false,
                            errorMsg: null
                        }
                    }

                }).filter((item: any) => item);

                const main_rec = {
                    parent_id: rec.parent_id,
                    transaction_id: rec.id,
                    description: rec.description,
                    transaction_at: rec.transaction_at,
                    header: header.length && header[0] || {}
                }
                storeData.push(main_rec);
            })

            const calculatedTotal = storeData.reduce((acc: any, record: any) => {
                acc['totalDebit'] += Math.abs(record['header'] && record['header']['debit'] || 0);
                acc['totalCredit'] += Math.abs(record['header'] && record['header']['credit'] || 0);
                return acc;
            }, { totalDebit: 0, totalCredit: 0 });

            setCalculatedTotalAmount({
                totalCredit: calculatedTotal['totalCredit'],
                totalDebit: calculatedTotal['totalDebit']
            })

            setData(storeData);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })

    }
    const filterAllAccountList = async (inputValue: string) => {
        const data = await fechAllAccountList(inputValue)
        return data;
    };

    const onChangeMasterChart = (event: any, groupIndex: number, recordIndex: number | null) => {
        setData((prevState: any) => {
            const objects = JSON.parse(JSON.stringify(prevState));
            const groupData = objects[groupIndex];
            if (recordIndex === null) {
                groupData['header']['master_chart_of_account_id'] = event;
            }
            objects.splice(groupIndex, 1, groupData)

            return objects;
        })
    }

    const _handleEdit = (groupIndex: number, recordIndex: number | null, toggle: boolean) => {
        setData((prevState: any) => {
            const objects = JSON.parse(JSON.stringify(prevState));
            const groupData = objects[groupIndex];

            if (recordIndex === null) {
                if (!toggle) {
                    groupData['header']['master_chart_of_account_id'] = groupData['header']['previous_master_chart_of_account_id'];
                }
                groupData['header']['isEdit'] = toggle;
            }
            objects.splice(groupIndex, 1, groupData)

            return objects;
        })
    }

    const _handleLoader = (groupIndex: number, recordIndex: number | null, toggle: boolean) => {
        setData((prevState: any) => {
            const objects = JSON.parse(JSON.stringify(prevState));
            const groupData = objects[groupIndex];
            if (recordIndex === null) {
                groupData['header']['showLoader'] = toggle;
            }
            objects.splice(groupIndex, 1, groupData)

            return objects;
        })
    }

    const _handleUpdate = (record: any, groupIndex: number, recordIndex: number | null) => {
        _handleLoader(groupIndex, recordIndex, true);
        const objects = JSON.parse(JSON.stringify(data));
        const groupData = objects[groupIndex];

        if (!record['master_chart_of_account_id']) {
            if (recordIndex === null) {
                groupData['header']['errorMsg'] = 'GL is required';
            }
            objects.splice(groupIndex, 1, groupData)
            setData(objects);
            return;
        }

        processData(record).then((resp: any) => {
            if (recordIndex === null) {
                groupData['header']['previous_master_chart_of_account_id'] = record['master_chart_of_account_id'];
                groupData['header']['isEdit'] = false;
            }
            objects.splice(groupIndex, 1, groupData)
            setData(objects);
            _handleLoader(groupIndex, recordIndex, false);
        }).catch((err: any) => {
            _handleLoader(groupIndex, recordIndex, false);
        })

    }

    const processData = (record: any) => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                breakdown_id: record['id'],
                master_chart_of_account_id: record['master_chart_of_account_id']['value'],
                branch_id: +branchData['id']
            }
            feedUpdate(params, (message: string, resp: any) => {
                toast.success(message);
                return resolve(resp)
            }, (message: string, resp: any) => {
                toast.error(message);
                return reject(resp);
            })
        })
    }

    return (
        <Modal show={show} onHide={() => onHide()} size='xl' className='modal-custom-xxl' animation={true} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Enter Transaction Adjustment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="create_adjustment_modal">
                    <table className="table table-default">
                        <thead>
                            <tr className="align-middle">
                                <th className="text-start">TXN ID</th>
                                <th className="text-center">TXN Date</th>
                                <th className="text-start">Description</th>
                                <th className="text-end">Amount</th>
                                <th className="text-end"></th>
                                <th className="text-start">ACC NUMBER</th>
                                <th className="text-end">DEBIT</th>
                                <th className="text-end">CREDIT</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 ? (
                                    <>
                                        {data.map((rec: any, index: any) => (
                                            <React.Fragment key={index}>
                                                {/* align-middle */}
                                                <tr className={index % 2 == 0 ? 'align-middle' : 'align-middle grey-background'}>
                                                    <th className="text-start">{rec.transaction_id}</th>
                                                    <th className="text-center">
                                                        {moment(rec.transaction_at).format('MM-DD-YYYY h:mm:ss')}
                                                    </th>
                                                    <th className="text-start text-truncate" style={{ maxWidth: '110px' }} title={rec.description || 'NA'}>{rec.description || 'NA'}</th>
                                                    <th className="text-end">{
                                                        rec?.header?.account_value < 0 ? `(${usdCurrencyFormat(Math.abs(rec?.header?.account_value))})` : usdCurrencyFormat(rec?.header?.account_value || 0)
                                                    }</th>
                                                    <th></th>
                                                    <th className="text-start">
                                                        {rec.header.isEdit ? (
                                                            <>
                                                                <AsyncSelect
                                                                    isClearable={false}
                                                                    placeholder={'Select'}
                                                                    defaultOptions={glCreditAccount}
                                                                    loadOptions={filterAllAccountList}
                                                                    defaultValue={rec.header.master_chart_of_account_id}
                                                                    onChange={(e: any) => onChangeMasterChart(e, index, null)}
                                                                    className='vp-dropdown'
                                                                    menuPosition='fixed'
                                                                    styles={selectCustomStyle}
                                                                    components={{ Option: SelectCustomOption }}
                                                                />
                                                                {rec.header.errorMsg && (
                                                                    <span className='text-danger'>{rec.header.errorMsg}</span>
                                                                ) || ''}
                                                            </>
                                                        ) : (
                                                            rec.header.master_chart_of_account_id ? (
                                                                <span className='text-wrap'>{rec.header.master_chart_of_account_id.label}</span>
                                                            ) : (
                                                                <span>NA</span>
                                                            )
                                                        )}
                                                    </th>
                                                    <th className="text-end">
                                                        {usdCurrencyFormat(Math.abs(rec?.header?.debit || 0))}
                                                    </th>
                                                    <th className="text-end">
                                                        {usdCurrencyFormat(Math.abs(rec?.header?.credit || 0))}
                                                    </th>
                                                    {
                                                        /*['Gross_Sale', 'Credit_Adjustment', 'Debit_Adjustment'].includes(rec.header.account_type) &&*/ hasEditAccess && (
                                                            <th className="text-center">
                                                                {rec.header.isEdit ? (
                                                                    <div>
                                                                        {rec.header.showLoader ? (
                                                                            <Spinner animation="border" size="sm" />
                                                                        ) : (
                                                                            <>
                                                                                <span
                                                                                    title='Cancel'
                                                                                    className='cursor-pointer text-danger'
                                                                                    onClick={() => _handleEdit(index, null, false)}
                                                                                >
                                                                                    <i className="fa fa-cancel" aria-hidden="true"></i>
                                                                                </span>
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <span
                                                                                    title='Save'
                                                                                    className='cursor-pointer text-success'
                                                                                    onClick={() => _handleUpdate(rec.header, index, null)}
                                                                                >
                                                                                    <i className="fa fa-save" aria-hidden="true"></i>
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <span
                                                                        title='Update'
                                                                        className='cursor-pointer text-secondary'
                                                                        onClick={() => _handleEdit(index, null, true)}
                                                                    >
                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                    </span>
                                                                )}
                                                            </th>
                                                        ) || <th></th>
                                                    }
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                        <tr>
                                            <td colSpan={3} className="text-end"><strong>Total</strong></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-end">
                                                <h6> {usdCurrencyFormat(calculatedTotalAmount['totalDebit'].toFixed(2))}
                                                </h6>
                                            </td>
                                            <td className="text-end">
                                                <h6> {usdCurrencyFormat(calculatedTotalAmount['totalCredit'].toFixed(2))}
                                                </h6>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={9} className='text-center'>No data found</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-end mt-3">
                        {/* <Button type="button" onClick={submitForm} variant="primary" className="me-2">Submit</Button> */}
                        <Button type='button' variant="secondary" onClick={() => onHide()}>Close</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ViewRegisterModal;
